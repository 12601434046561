import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IncidentAttendeeModel } from '../../models/';
import { IncidentAttendeesModalComponent } from './';
import { TIEROptions } from '../../services';
import { isNorU } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-incident-attendees',
    template: `
        <div class="hstack mb-2">
            <button type="button" class="btn btn-primary ms-auto" (click)="add(true)">
                <i class="fa-solid fa-plus"></i>
            </button>
        </div>

        <div class="alert alert-success mb-0" role="alert" *ngIf="!attendees || attendees.length <= 0">
            <i class="fa-solid fa-exclamation-circle"></i> 0 incident responders.
        </div>

        <table class="table" *ngIf="attendees && attendees.length > 0">
            <thead>
                <tr>
                    <th>Attendee</th>
                    <th *ngIf="!noTrackTimeOption">Duration</th>
                    <th>Skills</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let attendee of attendees; index as i">
                    <td>{{attendee.TeamMember?.FullName}}</td>
                    <td *ngIf="!noTrackTimeOption">{{ attendee.Start | durationdate:attendee.End }}</td>
                    <td>{{attendee.SkillModules?.length}} selected</td>
                    <td class="minwidth">
                        <div class="hstack">
                            <button type="button" class="btn btn-primary btn-sm me-1" (click)="add(false, i)">
                                <i class="fa-solid fa-edit"></i>
                            </button>
                            <button type="button" class="btn btn-danger btn-sm" (click)="delete(i)">
                                <i class="fa-solid fa-trash"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>`
})
export class IncidentAttendeesComponent implements OnChanges {
    @Input() attendees : IncidentAttendeeModel[] | undefined = [];
    @Output() attendeesChange : EventEmitter<IncidentAttendeeModel[]> = new EventEmitter<IncidentAttendeeModel[]>();

    @Input() start : string | null | undefined = null;
    @Input() end : string | null | undefined = null;

    public noTrackTimeOption : boolean = false;

    constructor(
        @Inject(NgbModal) private modalService : NgbModal,
        @Inject(TIEROptions) private options : TIEROptions
      ) {
            this.noTrackTimeOption = this.options.get('org-incident-no-track-time') === "true";
        }

    ngOnChanges(changes: SimpleChanges): void {
        if(!isNorU(changes['start']) && !changes['start']?.isFirstChange())
            this.start = changes['start'].currentValue;

        if(!isNorU(changes['end']) && !changes['end']?.isFirstChange())
            this.end = changes['end'].currentValue;
    }

    public add(multi : boolean = false, index : number | null = null) : void {
        const modalRef = this.modalService.open(IncidentAttendeesModalComponent, { size: 'lg' });

        modalRef.componentInstance.index = index;
        modalRef.componentInstance.attendees = this.attendees ?? [];
        modalRef.componentInstance.multi = multi;
        modalRef.componentInstance.start = this.start;
        modalRef.componentInstance.end = this.end;

        modalRef.result.then((result : IncidentAttendeeModel[]) => {
            this.attendees = result;
            this.attendeesChange.emit(result);
        }).catch(() => {});
    }

    public delete(index : number) : void {
        if(this.attendees) {
            this.attendees.splice(index, 1);
            this.attendeesChange.emit(this.attendees);
        }
    }
}
