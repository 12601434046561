<form class="col-md-8 offset-md-2 mt-3" role="form" #resourceModifyForm="ngForm" name="resourceModifyForm" (ngSubmit)="save()" novalidate autocomplete="none">
    <div>
        <div class="row mb-3">
            <label for="template" class="col-sm-3 col-form-label fw-bold">Template</label>
            <div class="col-sm-9">
                <input id="template" type="text" readonly class="form-control-plaintext" [value]="resource.EquipmentTemplate?.Name">
            </div>
        </div>
        <div class="row mb-3">
            <label for="reference" class="col-sm-3 col-form-label fw-bold">Reference</label>
            <div class="col-sm-9">
                <div class="input-group">
                    <input type="text" #reference="ngModel" id="reference" name="reference" class="form-control" [class.is-invalid]="reference.invalid" [(ngModel)]="resource.Reference" required>
                    <button class="btn btn-secondary" type="button" (click)="generateReference()"><i class="fa-solid fa-sync"></i></button>
                </div>
                <p [hidden]="reference.valid" class="form-text text-danger">A reference is required.</p>
            </div>
        </div>
        <div class="row mb-3">
            <label for="cost" class="col-sm-3 col-form-label fw-bold">Cost</label>
            <div class="col-sm-9">
                <div class="input-group">
                    <div class="input-group-text">£</div>
                    <input type="number" id="cost" #cost="ngModel" name="cost" class="form-control" [class.is-invalid]="cost.invalid" [(ngModel)]="resource.Cost" min="0.01" max="999999">
                </div>
                <p class="form-text text-danger" [hidden]="cost.valid">Cost cannot be 0 or under and cannot be over 999999.</p>
            </div>
        </div>
        <div class="row mb-3">
            <label for="weight" class="col-sm-3 col-form-label fw-bold">Weight</label>
            <div class="col-sm-9">
                <div class="input-group">
                    <input type="number" id="weight" name="weight" #weight="ngModel" class="form-control" [class.is-invalid]="weight.invalid" [(ngModel)]="resource.Weight" min="0.01" max="999999">
                    <div class="input-group-text">KG</div>
                </div>
                <p class="form-text text-danger" [hidden]="weight.valid">Weight cannot be 0 or under and cannot be over 999999.</p>
            </div>
        </div>
        <div class="row mb-3">
            <label for="manufacturer" class="col-sm-3 col-form-label fw-bold">Manufacturer</label>
            <div class="col-sm-9">
                <input type="text" id="manufacturer" name="manufacturer" class="form-control" [(ngModel)]="resource.Manufacturer">
            </div>
        </div>
        <div class="row mb-3">
            <label for="manufacturerserial" class="col-sm-3 col-form-label fw-bold">Manufacturer Serial No</label>
            <div class="col-sm-9">
                <input type="text" id="manufacturerserial" name="manufacturerserial" class="form-control" [(ngModel)]="resource.ManufacturerSerial">
            </div>
        </div>
        <div class="row mb-3">
            <label for="purchasedate" class="col-sm-3 col-form-label fw-bold">Purchase Date</label>
            <div class="col-sm-9">
                <tiertimepicker id="purchasedate" name="purchasedate" [(ngModel)]="resource.PurchaseDate"></tiertimepicker>
            </div>
        </div>
        <div class="row mb-3">
            <label for="expirydate" class="col-sm-3 col-form-label fw-bold">Expiry Date</label>
            <div class="col-sm-9">
                <tiertimepicker id="expirydate" name="expirydate" [(ngModel)]="resource.ExpiryDate"></tiertimepicker>
            </div>
        </div>
        <div class="row mb-3" *ngIf="resource?.EquipmentTemplate?.IsAllocatable">
            <label for="allocatedto" class="col-sm-3 col-form-label fw-bold">Allocated To</label>
            <div class="col-sm-9">
                <tierdropdown
                    id="allocatedto"
                    name="allocatedto"
                    source="api/teammembers"
                    placeholder="Select Team Member"
                    [(ngModel)]="resource.AllocatedToId"
                    [allowNew]=false
                    bindId="Id"
                    bindValue="FullName">
                </tierdropdown>
            </div>
        </div>
        <div class="row mb-3">
            <label for="checkduestatic" class="col-sm-3 col-form-label fw-bold">Check Due</label>
            <div class="col-sm-9">
                <input id="checkduestatic" type="text" readonly class="form-control-plaintext" [value]="(resource.CheckDueDate | transformdate: 'dd-MM-yyyy') || 'N/A'">
            </div>
        </div>
        <div class="row mb-3">
            <label for="checkedbystatic" class="col-sm-3 col-form-label fw-bold">Check By</label>
            <div class="col-sm-9">
                <input id="checkedbystatic" type="text" readonly class="form-control-plaintext" [value]="resource.CheckedBy?.FullName || 'N/A'">
            </div>
        </div>
        <div class="row mb-3">
            <label for="condition" class="col-sm-3 col-form-label fw-bold">Condition</label>
            <div class="col-sm-9">
                <tierdropdown
                    id="condition"
                    name="condition"
                    source="api/equipment/resources/conditiontypes"
                    [httpparams]="{ allowDefault: true }"
                    placeholder="Select Condition Type"
                    [(ngModel)]="resource.EquipmentResourceConditionTypeId"
                    [allowNew]=false
                    bindId="Id"
                    bindValue="Name"
                    defaultField="IsDefault">
                </tierdropdown>
            </div>
        </div>
        <div class="row mb-3">
            <label for="conditioncomments" class="col-sm-3 col-form-label fw-bold">Condition Comments</label>
            <div class="col-sm-9">
                <textarea class="form-control" rows="3" id="conditioncomments" name="conditioncomments" [(ngModel)]="resource.ConditionComments"></textarea>
            </div>
        </div>
        <tier-equipment-properties name="equipmentproperties" [attributes]="resource.EquipmentTemplate?.EquipmentTemplateAttributes ?? []" [(ngModel)]="resource.EquipmentResourceProperties"></tier-equipment-properties>
    </div>
    <div class="row mb-4">
        <div class="text-end pe-2">
            <div>
                <button type="button" class="btn btn-dark me-2"
                    (click)="openCheckModal()"
                    [disabled]="resourceModifyForm.invalid">
                    Check
                </button>
                <button type="button" class="btn btn-info me-2"
                    uiSref="equipment@resources@history"
                    [uiParams]="{ id: resource.Id }"
                    [disabled]="!resource.Id">
                    History
                </button>
                <button type="button" class="btn me-2"
                        [hidden]="!resource.Id"
                        [ngClass]="{ 'btn-success': resource.IsActive, 'btn-danger': !resource.IsActive}"
                        [textContent]="resource.IsActive ? 'Active' : 'Inactive'"
                        [disabled]="resourceModifyForm.invalid"
                        (click)="delete()">
                </button>
                <button class="btn btn-secondary me-2"
                        type="button"
                        (click)="cancel()">
                    Cancel
                </button>
                <button class="btn btn-primary"
                    type="submit"
                    [disabled]="resourceModifyForm.invalid">
                    Save
                </button>
            </div>
        </div>
    </div>
</form>
