
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridsterItemComponent } from 'angular-gridster2';
import { NgChartsModule } from 'ng2-charts';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';

import { TIERLocalStorage, TIERAuth, TIERAPICalls, TIERConfig } from '../services';

import {
    TestWidgetComponent,
    NotFoundWidgetComponent,
    NoDataWidgetComponent,
    MyModulesWidgetComponent,
    MyFailedModulesWidgetComponent,
    MyExpiringModulesWidgetComponent,
    MyExpiredModulesWidgetComponent,
    FacilitatedSessionsWidgetComponent,
    EquipmentAllocationWidgetComponent,
    TeamMembersByRoleWidgetComponent,
    TeamMembersNotInRoleWidgetComponent,
    NonCriticalExpiredModulesWidgetComponent,
    NonCriticalExpiringModulesWidgetComponent,
    TeamExpiredModulesWidgetComponent,
    TeamExpiringModulesWidgetComponent,
    TeamFailedModulesWidgetComponent,
    SessionsAttendedWidgetComponent,
    IncidentAttendanceWidgetComponent,
    TimeSpentTrainingWidgetComponent,
    EquipmentConditionWidgetComponent,
    EquipmentValidityWidgetComponent,
    IncidentPerTypeWidgetComponent
} from './'
import { WidgetLibrary } from '../shared/decorators/WidgetsLibrary.decorator';

let Widgets : Array<any> = [
    TestWidgetComponent,
    NotFoundWidgetComponent,
    NoDataWidgetComponent,
    MyModulesWidgetComponent,
    MyFailedModulesWidgetComponent,
    MyExpiringModulesWidgetComponent,
    MyExpiredModulesWidgetComponent,
    FacilitatedSessionsWidgetComponent,
    EquipmentAllocationWidgetComponent,
    TeamMembersByRoleWidgetComponent,
    TeamMembersNotInRoleWidgetComponent,
    NonCriticalExpiredModulesWidgetComponent,
    NonCriticalExpiringModulesWidgetComponent,
    TeamExpiredModulesWidgetComponent,
    TeamExpiringModulesWidgetComponent,
    TeamFailedModulesWidgetComponent,
    SessionsAttendedWidgetComponent,
    IncidentAttendanceWidgetComponent,
    TimeSpentTrainingWidgetComponent,
    EquipmentConditionWidgetComponent,
    EquipmentValidityWidgetComponent,
    IncidentPerTypeWidgetComponent,
];

@NgModule({
    declarations: Widgets,
    imports: [
        FormsModule,
        CommonModule,
        GridsterItemComponent,
        NgChartsModule,
        SharedModule
    ],
    providers: [
        TIERLocalStorage,
        TIERAuth,
        TIERAPICalls,
        TIERConfig
    ],
    exports: Widgets
})
export class WidgetsModule {
    constructor() {
        Widgets.forEach((item) => {
            WidgetLibrary.RegisterWidget(item);
        })
    }
}
