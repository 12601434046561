
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgbCollapseModule, NgbTooltipModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';

import {
    TIERDropdownComponent,
    TIERPanelSliderComponent,
    TIERSpinnerComponent,
    TIERTitleComponent,
    TIERNewsComponent,
    TIERTimepickerComponent,
    TIERPhonenumberComponent,
    TIERFilterComponent,
    TIERFilterSearchComponent,
    TIERFilterDropdownComponent,
    TIERFilterDateComponent,
    TIERFilterTickboxComponent,
    TIERTimespanComponent,
    TIERUploadComponent,
    TIERUploadButtonComponent,
    TIERSkillTooltipComponent,
    TIERTeammembersBadgeComponent,
    TIERTeammembersListingComponent,
    TIERTelltimeComponent,
    TIERMappingComponent,
    TIERMappingModalComponent,
    TIERMappingButtonComponent,
    TIERTypeDropdownComponent,
    TIERTypeDropdownModalComponent,
    TIERWeatherComponent,
    TIERMenuComponent,
    TIERMenuChildComponent
 } from './components';

import {
    DuplicateFieldValidationDirective,
    DateValidationDirective,
    ConvertToTrueOrFalseDirective,
    ContainsDuplicateEntryDirective,
    LongPressDirective,
    URLValidationDirective
} from './directives';

import {
    SearchTextPipe,
    TransformDatePipe,
    FilterByObjectPropertyPipe,
    DurationDatePipe,
    TransformJsonPipe
} from './pipes';

@NgModule({
    declarations: [
        TIERDropdownComponent,
        TIERPanelSliderComponent,
        TIERSpinnerComponent,
        TIERNewsComponent,
        SearchTextPipe,
        TransformDatePipe,
        TIERTitleComponent,
        TIERTimepickerComponent,
        TIERPhonenumberComponent,
        DuplicateFieldValidationDirective,
        DateValidationDirective,
        TIERFilterComponent,
        TIERFilterSearchComponent,
        TIERFilterDropdownComponent,
        TIERFilterDateComponent,
        TIERFilterTickboxComponent,
        ConvertToTrueOrFalseDirective,
        TIERTimespanComponent,
        TIERUploadComponent,
        TIERUploadButtonComponent,
        TIERSkillTooltipComponent,
        TIERTeammembersBadgeComponent,
        TIERTeammembersListingComponent,
        TIERTelltimeComponent,
        ContainsDuplicateEntryDirective,
        TIERMappingComponent,
        TIERMappingModalComponent,
        TIERMappingButtonComponent,
        FilterByObjectPropertyPipe,
        TIERTypeDropdownComponent,
        TIERTypeDropdownModalComponent,
        DurationDatePipe,
        TransformJsonPipe,
        LongPressDirective,
        TIERWeatherComponent,
        TIERMenuComponent,
        TIERMenuChildComponent,
        URLValidationDirective
    ],
    imports: [
        CommonModule,
        NgSelectModule,
        FormsModule,
        NgxSpinnerModule,
        BrowserAnimationsModule,
        FlatpickrModule.forRoot(),
        NgbCollapseModule,
        NgbTooltipModule,
        NgbDropdownModule,
        UIRouterModule.forChild()
    ],
    providers: [
    ],
    exports: [
        TIERDropdownComponent,
        TIERPanelSliderComponent,
        TIERSpinnerComponent,
        TIERNewsComponent,
        SearchTextPipe,
        TransformDatePipe,
        TIERTitleComponent,
        TIERTimepickerComponent,
        TIERPhonenumberComponent,
        DuplicateFieldValidationDirective,
        DateValidationDirective,
        TIERFilterComponent,
        TIERFilterSearchComponent,
        TIERFilterDropdownComponent,
        TIERFilterDateComponent,
        TIERFilterTickboxComponent,
        ConvertToTrueOrFalseDirective,
        TIERTimespanComponent,
        TIERUploadComponent,
        TIERUploadButtonComponent,
        TIERSkillTooltipComponent,
        TIERTeammembersBadgeComponent,
        TIERTeammembersListingComponent,
        TIERTelltimeComponent,
        ContainsDuplicateEntryDirective,
        TIERMappingComponent,
        TIERMappingModalComponent,
        TIERMappingButtonComponent,
        FilterByObjectPropertyPipe,
        TIERTypeDropdownComponent,
        DurationDatePipe,
        TransformJsonPipe,
        LongPressDirective,
        TIERWeatherComponent,
        TIERMenuComponent,
        TIERMenuChildComponent,
        URLValidationDirective
    ]
})
export class SharedModule {
    constructor() {}
}
