'use strict'

import { Component, Inject, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ContactsModel, ContactsModuleModel, EquipmentResourceModel } from 'src/tier/models/';
import { TIERAPICalls, TIERToast, TIERProfilePicture } from '../../services';
import { http2Error } from 'src/tier/tier.utils';
import { ListingComponent } from '../';

@Component({
    selector: 'tier-contacts',
    templateUrl: './contacts.template.html'
})
export class ContactsComponent implements OnInit {
    public contacts : ContactsModel[] = [];
    public searchText : string | undefined = undefined;

   constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(TIERProfilePicture) private pp : TIERProfilePicture,
        @Inject(NgbModal) private modalService : NgbModal) { };

    ngOnInit(): void {
        this.apicall.get<ContactsModel[]>('api/contacts/').subscribe({
            next: (response : ContactsModel[]) => {
                this.contacts = this.augment(response);
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    private augment(contacts : ContactsModel[]) : ContactsModel[] {
        contacts.forEach((item) => {
            if(item.ProfilePicture !== null) {
                this.pp.get(item.Id).subscribe({
                    next: (response) => {
                        item.profilePictureURL = response;
                    }
                })
            } else {
                item.profilePictureURL = this.pp.getDefaultPic();
            }
            item.showModules = true;
        });

        return contacts;
    }

    public showAll(show : boolean) : void {
        this.contacts.forEach((item) => {
            item.showModules = show;
        });
    }

    public toggleContact(contact : ContactsModel) : void {
        contact.showModules = !contact.showModules;
    }

    public showCriticalModulesNotMet(module : ContactsModuleModel) {
        if(!module.CriticalModulesNotMet.length)
            return;

        this.showListingModal(module.CriticalModulesNotMet, "Critical Modules Not Met", "fa-cog")
    }

    public showInvalidEquipment(equipment : EquipmentResourceModel[]) {
        if(!equipment.length)
            return;

        this.showListingModal(
            equipment.map(
                (resource) => `(${resource.EquipmentTemplate?.Name}) ${resource.Reference} - ${resource.EquipmentResourceConditionType?.Name}`
            ),
         "Invalid Equipment",
        "fa-box");
    }

    private showListingModal(entries : string[], title : string, icon : string) {
        const modalRef = this.modalService.open(ListingComponent, { size: 'sm' });

        modalRef.componentInstance.entries = entries;
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.icon = icon;

        modalRef.result.then(() => {}).catch(() => {});
    }
}
