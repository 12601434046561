'use strict'

import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { TIERAPICalls, TIERToast, TIEROptions, TIERConfig } from '../../services';
import { NgForm } from '@angular/forms';

import { OptionModel } from '../../models';
import { randomString, isNorU, http2Error } from '../../tier.utils';

@Component({
    selector: 'tier-management-orgsettings',
    templateUrl: "./orgsettings.template.html"
})
export class OrgSettingsComponent implements OnInit {
    @ViewChild('settingsForm') settingsForm!: NgForm;

    public options : OptionModel = {};
    public twilioPhoneNumbers : any = [];
    public smsHookField : string | null = null;

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(TIEROptions) private opt : TIEROptions,
        @Inject(TIERConfig) private config : TIERConfig
    ) { };

    public daysOfWeek = [
        { id: 2, name: 'Monday' },
        { id: 3, name: 'Tuesday' },
        { id: 4, name: 'Wednesday' },
        { id: 5, name: 'Thursday' },
        { id: 6, name: 'Friday' },
        { id: 7, name: 'Saturday' },
        { id: 1, name: 'Sunday' }
    ];

    ngOnInit(): void {
        this.options = this.opt.getAll();

        if(!isNorU(this.options["org-twilio-auth-sid"]) && !isNorU(this.options["org-twilio-auth-token"])) {
            this.apicall.get('api/sms').subscribe({
                next: (result : any ) => {
                    this.twilioPhoneNumbers = result;
                },
                error: (err) => {
                    this.alert.error(http2Error(err));
                }
            })
        }

        this.smsHook();
    }

    public save() : void {
        let reload = this.opt.saveAndReload(this.options);

        if(reload)
            reload.subscribe({
                next: () => {
                    this.alert.success('Settings have been saved.');
                    this.settingsForm.form.markAsPristine();
                },
                error: (err) => {
                    this.alert.error(http2Error(err));
                }
            })
    }

    public genToken() : void {
        this.options['org-twilio-basicauth-password'] = randomString(32);
        this.settingsForm.form.markAsDirty();
        this.smsHook();
    }

    public smsHook() : void {
        if(isNorU(this.options['org-twilio-basicauth-password']) || isNorU(this.options['org-twilio-basicauth-username']))
            return;

        let url = new URL(this.config.get("apiUrl"));
        this.smsHookField = url.protocol +
            '//' +
            this.options['org-twilio-basicauth-username'] +
            ':' +
            this.options['org-twilio-basicauth-password'] +
            '@' +
            url.host +
            '/api/sms';
    }
}
