'use strict';

import { Injectable, Inject } from '@angular/core';
import { TIERHelpers } from './';
import {
    map,
    Observable,
    throwError,
    catchError
  } from 'rxjs';

import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { isNorU } from 'src/tier/tier.utils';

export interface blobDownload {
    'blob' : Blob | null,
    'contentDisposition' : any
}

@Injectable()
export class TIERDownload
{
    constructor(@Inject(HttpClient) private http : HttpClient,
                @Inject(TIERHelpers) private helpers : TIERHelpers) { }

    public download(url : string, method : string, params : HttpParams, body : any = null) : Observable<blobDownload> {
        return this.http.request<Blob>(
            method,
            url,
            {
                'responseType': 'blob' as 'json',
                'headers': new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }),
                'observe': 'response',
                'params' : params,
                'body': body
            }).pipe(
                map((bl : HttpResponse<Blob>) => {
                    return {
                        'blob': bl.body,
                        contentDisposition: this.parseContentDisposition(bl.headers.get('Content-Disposition'))
                    }
                }),
                catchError((error: HttpErrorResponse) => { return throwError(() => error)})
            );
    }

    public downloadLinkFromServer(url : string , method : string = 'GET', params : HttpParams = new HttpParams(), body : any = null) : Observable<HTMLAnchorElement> {
         return this.download(this.helpers.as(url), method, params, body).pipe(
            map((bl : blobDownload) => {
                let link = document.createElement('a');

                if(bl.blob)
                    link.href = window.URL.createObjectURL(bl.blob);

                link.download = bl.contentDisposition.filename;
                return link;
            })
         );
    }

    private parseContentDisposition(contentDisposition : string | null) : any {
        let returnObj : any = {};

        if (!isNorU(contentDisposition))
            contentDisposition!.split(';').forEach((value) => {
                let valueSplit : string[] = value.trim().split('=');
                returnObj[valueSplit[0]] = (valueSplit.length === 1 ? valueSplit[0] : valueSplit[1]).replace(/^"+|"+$/ug, '');
            });

        return returnObj;
    };
}
