'use strict'

import { Component, Inject, OnInit } from '@angular/core';
import { format, subMonths } from "date-fns";
import { HttpParams } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/angular';

import { IncidentListingModel, IncidentEntryModel, IncidentModel } from '../../models/';
import { TIERAPICalls, TIERToast, TIERAuth, TIEROptions } from '../../services';
import { http2Error, calcUUID, isNorU } from 'src/tier/tier.utils';
import { EditIncidentsComponent, IncidentMessagesComponent } from './';

@Component({
    selector: 'tier-incidents',
    templateUrl: "./incidents.template.html",
    styles: `.goldstar {
        color: gold;
    }`
})
export class IncidentsComponent implements OnInit {
    public incidents : IncidentEntryModel[] = [];
    public total : number = 0;
    public page : number = 1;
    public noTrackTimeOption : boolean = false;

    public filterConfig = [
        {
            name: calcUUID(),
            formControl: "search",
            label: "Search Text",
            value: "searchText"
        },
        {
            name: calcUUID(),
            label: "Date",
            equality: 'dateEquality',
            formControl: "date",
            format: "dd-MM-yyyy",
            value: "date"
        },
        {
            name: calcUUID(),
            label: "Show Inactive",
            formControl: "tickbox",
            value: "showInactive"
        },
        {
            name: calcUUID(),
            label: "Show Unconcluded (Ignores Date Ranges)",
            formControl: "tickbox",
            value: "showUnconcluded"
        }
    ];

    public values = {
        skip: 0,
        amount: 10,
        searchText: '',
        date: format(subMonths(new Date(), 1), 'dd-MM-yyyy'),
        dateEquality: 'morethan',
        showUnconcluded: true,
        showInactive: true,
        column: 'Date',
        direction: 'desc'
    };

    public headers = [
        { name: 'Date', centered: true, orderable: true },
        { name: 'Summary', centered: false, orderable: false },
        { name: 'Ref', centered: false, orderable: true },
        { name: 'Active', centered: true, orderable: true }
    ];

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(TIEROptions) private options : TIEROptions,
        @Inject(TIERAuth) private auth : TIERAuth,
        @Inject(NgbModal) private modalService : NgbModal,
        @Inject(StateService) private state : StateService
    ) {
        this.noTrackTimeOption = this.options.get('org-incident-no-track-time') === "true";
    }

    ngOnInit(): void {
         this.get();
    }

    public get() : void {
        this.apicall.get<IncidentListingModel>('api/incidents', new HttpParams({'fromObject': { ...this.values }})).subscribe({
            next: (response : IncidentListingModel) => {
                this.incidents = response.ObjectList;
                this.total = response.Total;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public changeOrder(header : any) : void {
        if (header.orderable === false)
            return;

        if (header.name === this.values.column) {
            if (this.values.direction === 'asc') {
                this.values.direction = 'desc';
            } else {
                this.values.direction = 'asc';
            }
        } else {
            this.values.column = header.name;
            this.values.direction = 'asc';
        }

        this.get();
    };

   private openIncidentModal(incident : IncidentModel = { IncidentAttendees: [], IncidentSubjects: [], CurrentWeather: null, ActivityLocation: {} }) : void {
        const modalRef = this.modalService.open(EditIncidentsComponent, { size: 'xl' });

        modalRef.componentInstance.incident = incident || {};

        modalRef.result.then((result : { incident: IncidentModel, status : boolean }) => {
            this.apicall.save<IncidentModel>('api/incidents/%s'.formUri(result.incident.Id), result.incident).subscribe({
                next: (response: IncidentModel) => {
                    this.alert.success("Incident saved.");
                    this.get();

                    if(result.status)
                        this.print(isNorU(response) ? result.incident.Id : response.Id);
                },
                error: (err) => { this.alert.error(http2Error(err)); }
            });
        }).catch(() => {});
    }

    public print(id : number | undefined) {
        if(!id)
            return;

        let url = this.state.href('reports@incidentinformation',
            {
                'IsAsc': true,
                'Column': 'Name',
                'Amount': 20,
                'Page': 1,
                'IncidentId': id
            }
        );
        window.open(url, '_blank');
    }

    public openMessageModal(id : number) {
        const modalRef = this.modalService.open(IncidentMessagesComponent, { size: 'lg' });

        modalRef.componentInstance.id = id;

        modalRef.dismissed.subscribe({
            next: () => {
                this.get();
            }
        })
    }

    public new() : void  {
        this.openIncidentModal({ Start: format(new Date(), 'dd-MM-yyyy HH:mm'), IncidentAttendees: [], IncidentSubjects: [], CurrentWeather: null, ActivityLocation: {} });
    }

    public edit(id : number) : void {
        this.apicall.get<IncidentModel>('api/incidents/%s'.formUri(id)).subscribe({
            next: (response : IncidentModel) => {
                this.openIncidentModal(response);
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }
}
