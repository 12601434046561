<div class="modal-header">
    <h3 class="modal-title">
        Choose Equipment
    </h3>
</div>
<div class="modal-body">
    <div class="row mb-3">
        <div class="hstack">
            <div>
                <button class="btn btn-primary me-2" role="button" (click)="toggle(false)">Expand</button>
                <button class="btn btn-primary" role="button" (click)="toggle(true)">Collapse</button>
            </div>
            <div class="col-sm-4 ms-auto col-lg-2">
                <input class="form-control"
                    type="text"
                    placeholder="Search..."
                    [(ngModel)]="searchText">
            </div>
        </div>
    </div>
    <tier-equipment-treeselector-node [search]="searchText" [(nodes)]="resources"></tier-equipment-treeselector-node>
</div>
<div class="modal-footer">
    <div>
        <button type="button" class="btn btn-secondary me-2" (click)="this.activeModal.dismiss()">
            Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="confirm()">
            Confirm
        </button>
    </div>
</div>
