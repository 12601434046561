import 'zone.js';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Inject, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgComponentOutlet } from '@angular/common';
import { GridsterModule } from 'angular-gridster2';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { UIRouterModule } from '@uirouter/angular';
import { NgbNavModule, NgbPaginationModule, NgbTypeaheadModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter, CalendarEventTitleFormatter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { WidgetsModule } from './widgets/widgets.module';
import { SharedModule } from './shared/shared.module';
import { ReportsModule } from './reports/reports.module';

import {
    TIERAuthInterceptor,
    TIERLocalStorage,
    TIERAuth,
    TIERAPICalls,
    TIERConfig,
    TIEROptions,
    TIERHelpers,
    TIERToast,
    TIERMessages,
    TIERErrorHandler,
    TIERAppInsights,
    TIERDownload,
    TIERProfilePicture,
    TIERUpload,
    TIERDialog,
    TIEREquipmentStack,
    TIEROrganization,
    TIERMenuSignals } from './services';

import {
    DashboardComponent,
    EmergencyContactsComponent,
    RoleLayoutComponent,
    BootstrapComponent,
    LoginComponent,
    PrivacyComponent,
    TandcComponent,
    RolesComponent,
    TeammembersComponent,
    TeammembersEntitlementsComponent,
    TeammembersPenaltyPointsComponent,
    TeammembersPasswordConfirmComponent,
    RoleSelectorComponent,
    MessagesComponent,
    ListingComponent,
    ContactsComponent,
    AuditLogComponent,
    OrgSettingsComponent,
    ModulesComponent,
    AssessmentsComponent,
    EditAssessmentsComponent,
    SessionsComponent,
    EditSessionsComponent,
    SessionsAttendenceComponent,
    SessionsCompleteComponent,
    SessionsCompleteCommentsComponent,
    ScheduleComponent,
    ScheduleCalendarComponent,
    ScheduleListComponent,
    ScheduleDetailsComponent,
    ScheduleCalendarTitleFormatter,
    IncidentsComponent,
    EditIncidentsComponent,
    IncidentMessagesComponent,
    ConcludeIncidentComponent,
    IncidentAttendeesComponent,
    IncidentAttendeesModalComponent,
    ModuleSelectorComponent,
    IncidentSubjectsComponent,
    IncidentSubjectsModalComponent,
    RespondingComponent,
    EquipmentTemplatesComponent,
    EquipmentTemplateComponent,
    EquipmentAttributesComponent,
    EquipmentResourcesComponent,
    EquipmentResourceAddComponent,
    EquipmentResourceEditComponent,
    EquipmentResourceCheckComponent,
    EquipmentPropertiesComponent,
    EquipmentResourceHistoryComponent,
    EquipmentCheckoutComponent,
    EquipmentCheckoutCheckInComponent,
    ReportBuilderComponent,
    ReportsComponent } from './components';

import { TIERMaximizeDirective } from './directives';
import { routes } from './tier.routes'

@NgModule({
    declarations: [
        TIERMaximizeDirective,
        DashboardComponent,
        EmergencyContactsComponent,
        RoleLayoutComponent,
        BootstrapComponent,
        LoginComponent,
        PrivacyComponent,
        TandcComponent,
        RolesComponent,
        TeammembersComponent,
        RoleSelectorComponent,
        TeammembersEntitlementsComponent,
        TeammembersPenaltyPointsComponent,
        TeammembersPasswordConfirmComponent,
        MessagesComponent,
        ListingComponent,
        ContactsComponent,
        AuditLogComponent,
        OrgSettingsComponent,
        ModulesComponent,
        AssessmentsComponent,
        EditAssessmentsComponent,
        SessionsComponent,
        EditSessionsComponent,
        SessionsAttendenceComponent,
        SessionsCompleteComponent,
        SessionsCompleteCommentsComponent,
        ScheduleComponent,
        ScheduleCalendarComponent,
        ScheduleListComponent,
        ScheduleDetailsComponent,
        IncidentsComponent,
        EditIncidentsComponent,
        IncidentMessagesComponent,
        ConcludeIncidentComponent,
        IncidentAttendeesComponent,
        IncidentAttendeesModalComponent,
        ModuleSelectorComponent,
        IncidentSubjectsComponent,
        IncidentSubjectsModalComponent,
        RespondingComponent,
        EquipmentTemplatesComponent,
        EquipmentTemplateComponent,
        EquipmentAttributesComponent,
        EquipmentResourcesComponent,
        EquipmentResourceAddComponent,
        EquipmentResourceEditComponent,
        EquipmentResourceCheckComponent,
        EquipmentPropertiesComponent,
        EquipmentResourceHistoryComponent,
        EquipmentCheckoutComponent,
        EquipmentCheckoutCheckInComponent,
        ReportBuilderComponent,
        ReportsComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            preventDuplicates: true,

            enableHtml: true,
            newestOnTop: true
        }),
        GridsterModule,
        WidgetsModule,
        HttpClientModule,
        NgComponentOutlet,
        FormsModule,
        SharedModule,
        UIRouterModule.forRoot({ states: routes, useHash: true, otherwise: '/dashboard' }),
        NgbNavModule,
        NgbPaginationModule,
        NgbTypeaheadModule,
        NgbDropdownModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        ReportsModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TIERAuthInterceptor,
            multi: true
        },
        {
            provide: CalendarEventTitleFormatter,
            useClass: ScheduleCalendarTitleFormatter
        },
        { provide: ErrorHandler, useClass: TIERErrorHandler },
        TIERAppInsights,
        TIERLocalStorage,
        TIEROptions,
        TIERAuth,
        TIERAPICalls,
        TIERConfig,
        TIERToast,
        TIERMessages,
        TIERProfilePicture,
        TIERDownload,
        TIERUpload,
        TIERDialog,
        TIEREquipmentStack,
        TIEROrganization,
        TIERMenuSignals
    ],
    bootstrap: [ BootstrapComponent ]
})
export class TierModule {
    constructor(
        @Inject(TIERLocalStorage) private localstore : TIERLocalStorage,
        @Inject(TIERAuth) private auth : TIERAuth,
        @Inject(TIERConfig) private config : TIERConfig,
        @Inject(TIERHelpers) private help : TIERHelpers)
    {
        this.localstore.setPrefix(this.config.get("clientId"));
        this.auth.fetchTokenFromStorage();

        String.prototype.formUri = function (...args) {
            let counter = 0;

            return this.replace(/%s/ug, function () {
                return !args[counter] ? "" : args[counter++];
            });
        };

        const self = this;
        String.prototype.formUrl = function (...args) {
            return self.help.as(this.formUri.apply(this, args));
        };
    }
}
