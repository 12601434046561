import { Component, Input, Output, EventEmitter, OnDestroy, Inject } from '@angular/core';
import { Map, map, latLng, tileLayer, Marker, marker, popup, bind, Icon } from "leaflet";
import { GeoSearchControl, osGridRefProvider, OpenStreetMapProvider } from '../../../../leaflet-geosearch';
import { VenueModel } from 'src/tier/models';
import { HttpHeaders } from '@angular/common/http';

import { TIERConfig, TIERToast, TIERAPICalls } from 'src/tier/services';
import { randomString, isNorU, http2Error } from '../../../tier.utils';

@Component({
    selector: 'tier-mapping',
    template: `<div [id]="ident" style="height: 300px;"></div>`,
})
export class TIERMappingComponent implements OnDestroy {
    @Input() location : VenueModel | null | undefined = null;
    @Output() locationChange : EventEmitter<VenueModel> = new EventEmitter<VenueModel>();

    @Input() tooltip : boolean = false;
    @Input() permission : string = 'r';

    public map : Map | null = null;
    public mark : Marker | null = null;
    public provider = null;
    public searchControl : any = null;
    public ident : string = randomString(8);

    constructor(
        @Inject(TIERConfig) private config : TIERConfig,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(TIERAPICalls) private apicall : TIERAPICalls
    ) {};

    ngAfterViewInit() : void {
        Icon.Default.prototype.options.iconUrl = "images/marker-icon.png";
        Icon.Default.prototype.options.shadowUrl = "images/marker-shadow.png";

        this.display();
        switch (this.permission) {
            case 'r':
                this.readOnly();
                break;
            case 'rw':
                this.readWrite(true);
                break;
            case 'auto':
                this.auto();
                break;
            default:
                console.error("permission invalid, permission should be r or rw");
        }
    }

    ngOnDestroy(): void {
        this.removeClickEvent();
    }

    private display() : void {
        this.map = map(this.ident, {
                        scrollWheelZoom: 'center',
                        doubleClickZoom: 'center',
                        touchZoom: 'center'
                    }).setView([51.4454, -2.6807], 9);
       tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { minZoom: 8, maxZoom: 18 }).addTo(this.map);
    }

    private readOnly() : void {
        this.removeClickEvent();
        this.clearMarker();

        this.map!.dragging.disable();
        if (this.isLocationValid(this.location)) {
            this.setLocationMarker(this.convertToLatLngObj(this.location), 14);
            if (this.tooltip === true)
                this.createPopup();
        }
    }

    private readWrite(save : boolean) : void {
        this.removeClickEvent();
        this.clearMarker();

        if (this.isLocationValid(this.location))
            this.setLocationMarker(this.convertToLatLngObj(this.location));
        this.addClickEvent(save);
        this.initGeoSearch();
    }

    private auto() : void {
        if (!isNorU(this.location?.Name)) {
                this.apicall.post<VenueModel>(
                    'api/namedlocations/',
                    JSON.stringify(this.location?.Name),
                    undefined,
                    new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8' })).
                subscribe({
                    next: (response : VenueModel) => {
                        this.saveLocation(response.Lat!, response.Lon!, response.OsGridRef ?? null);
                        this.readOnly();
                    },
                    error: (err) => {
                        if (err.status === 404) {
                            this.readWrite(true);
                        } else {
                            this.alert.error(http2Error(err));
                        }
                    }
                })
        } else {
            this.readWrite(true);
        }
    };

    private setLocationMarker(latLng : any, zoom : number = 8) : void {
        if (this.mark)
            this.map!.removeLayer(this.mark);

        if (zoom < 1 && zoom > 15) {
            console.log("Zoom needs to be between 1 and 15");
            return;
        }

        this.mark = marker(latLng).addTo(this.map!);
        this.map!.setView(latLng, zoom);
    }

    private clearMarker() : void {
        if (this.mark) {
            this.map!.removeLayer(this.mark);
            this.mark = null;
        }
    }

    private createPopup() : void {
        if (!this.mark)
            return;

        let popupTmp = popup({
            closeOnClick: false,
            closeButton: false
        }).setContent((this.location?.Name ? "Name: " + this.location.Name + "<br>" : "") +
            "Lat: " + this.location?.Lat + "<br>" +
            "Long: " + this.location?.Lon + "<br>" +
            (isNorU(this.location?.OsGridRef) && this.location?.OsGridRef !== null ? "OSGridRef: " + this.location?.OsGridRef + "<br>" : "") +
            "<a href='" + this.config.get('googleMapLink') + this.location?.Lat + "," + this.location?.Lon + "' target=\"_blank\">Open Location</a>");

        this.mark.bindPopup(popupTmp);
        this.mark.openPopup();
    }

    private addClickEvent(save : boolean) : void {
        if (this.map)
            this.map.on('click', bind(this.clickEvent, this, save));
   }

    private clickEvent(save : boolean, e : any) : void {
        if (e.originalEvent.defaultPrevented === true)
            return;

        this.setLocationMarker(e.latlng);
        if (save)
            this.saveMarkerToLocation();
    };

    private removeClickEvent() : void {
        if (this.map)
            this.map.off('click');
    };

    private initSearchProvider() : void {
        this.searchControl = GeoSearchControl({
            provider: [ new osGridRefProvider(), new OpenStreetMapProvider() ],
            style: 'bar',
            stopClickPropagation: true,
            autoClose: true
        });
    };

    private initGeoSearch() : void {
        if (!this.searchControl)
            this.initSearchProvider();

        this.map!.addControl(this.searchControl);

        this.map!.on('geosearch/showlocation', bind((e : any) => {
            this.setLocationMarker(latLng(e.location.y, e.location.x));
            this.saveLocation(e.location.y, e.location.x, null);
        }, this));
    };

    private saveMarkerToLocation() : void {
        if (!this.mark)
            return;

        let latLng = this.mark.getLatLng();
        this.saveLocation(latLng.lat, latLng.lng, null);
    };

    private saveLocation(lat : number, lon : number, osGridRef : string | null) : void {
        let location : VenueModel = { Lat: this.to5dp(lat), Lon: this.to5dp(lon), OsGridRef: osGridRef ?? undefined };

        if (!this.isLocationValid(location))
            return;

        this.location = Object.assign(this.location!, location);
        this.locationChange.emit(this.location);
    };

    private convertToLatLngObj(location : VenueModel | null | undefined) : any {
        if(!location && this.isLocationValid(location))
            return;

        return latLng(location!.Lat!, location!.Lon!);
    };

    private isLocationValid(location : VenueModel | null | undefined) : boolean {
        let latReg = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/u;
        let lngReg = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/u;

        if (isNorU(location))
            return false;

        if (isNorU(location?.Lat) || !latReg.test(location!.Lat!.toString()))
            return false;

        if (isNorU(location?.Lon) || !lngReg.test(location!.Lon!.toString()))
            return false;

        return true;
    };

    private to5dp(number : number) : number {
        return Math.round(number * 1e6) / 1e6;
    };
}
