<div class="modal-header">
    <h3 class="modal-title" id="modal-title">Session Planning</h3>
</div>
<form role="form" #sessionForm="ngForm" name="sessionForm" novalidate autocomplete="none">
    <div class="modal-body mb-3">

        <div class="alert alert-warning" role="alert" [hidden]="!session.hasUnmarkedSkills">
            You have added new skills, faciliators or team members to this session when it has been finalised.<br>
            Some members will not have been assessed. You must finalise this session again to mark all team members as assessed.
        </div>

        <div class="alert alert-warning" role="alert" [hidden]="!session.IsComplete">
            You are unable to change session date & time when the session has been finalised.
        </div>

        <div class="row mb-3">
            <label for="title" class="col-sm-3 col-form-label fw-bold">Title</label>
            <div class="col-sm-9">
                <input type="text" #title="ngModel" name="title" id="title" class="form-control" [ngClass]="{ 'is-invalid': title.invalid, 'is-valid': title.valid && !title.pristine }" placeholder="Title" [(ngModel)]="session.Title" maxlength="50" required>
                <p [hidden]="title.valid" class="form-text text-danger">Title is required.</p>
            </div>
        </div>
        <div class="row mb-3">
            <label for="url" class="col-sm-3 col-form-label fw-bold">URL</label>
            <div class="col-sm-9">
                <input type="text" #url="ngModel" name="url" id="url" [ngClass]="{ 'is-invalid': url.invalid, 'is-valid': url.valid && !url.pristine }" class="form-control" placeholder="URL" [(ngModel)]="session.URL" maxlength="1024" URLValidation>
                <p [hidden]="url.valid" class="form-text text-danger">URL Invalid.</p>
            </div>
        </div>
        <div class="row mb-3">
            <label for="start" class="col-sm-3 col-form-label fw-bold">Start Date &amp; Time</label>
            <div class="col-sm-9">
                <tiertimepicker id="start" name="start" #startDate="ngModel" [model]="startDate" [withTime]="true" format="d-m-Y H:i" [(ngModel)]="session.StartDate" [readonly]="session.IsComplete ?? false" required></tiertimepicker>
                <p [hidden]="startDate.valid" class="form-text text-danger">Valid start date is required.</p>
            </div>
        </div>
        <div class="row mb-3">
            <label for="end" class="col-sm-3 col-form-label fw-bold">End Date &amp; Time</label>
            <div class="col-sm-9">
                <tiertimepicker id="end" name="end" #endDate="ngModel" [model]="endDate" [(ngModel)]="session.EndDate" [withTime]="true" [DateValidation]="[ { value: startDate.value, equality: 'morethan', ident: 'sessionend' } ]" ValidationFormat="dd-MM-yyyy HH:mm" format="d-m-Y H:i" [readonly]="session.IsComplete ?? false" required></tiertimepicker>
                <p [hidden]="endDate.valid" class="form-text text-danger">Valid end date is required and must be after start date.</p>
            </div>
        </div>
        <div class="row mb-3">
            <label for="atdd" class="col-sm-3 col-form-label fw-bold">Attendees</label>
            <div class="col-sm-9">
                <div class="hstack">
                    <div class="w-100 me-2">
                        <tierdropdown
                            name="atdd"
                            source="api/teammembers"
                            placeholder="Select Team member"
                            [(ngModel)]="session.Attendees"
                            [multi]=true
                            bindValue="FullName">
                        </tierdropdown>
                    </div>
                    <div class="ms-auto">
                        <button type="button" class="btn btn-info" [disabled]="sessionForm.invalid || !session.Attendees || session.Attendees.length <= 0" (click)="openAttendencePage(session.Id)">
                            <i class="fa-solid fa-check"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <label for="modules" class="col-sm-3 col-form-label fw-bold">Modules</label>
            <div class="col-sm-9">
                <tierdropdown
                    name="skdd"
                    source="api/modules"
                    placeholder="Select skill module"
                    [(ngModel)]="session.SkillModules"
                    [multi]=true
                    bindValue="Name">
                </tierdropdown>
            </div>
        </div>
        <div class="row mb-3">
            <label for="venue" class="col-sm-3 col-form-label fw-bold">Venue</label>
            <div class="col-sm-9">
                <div class="hstack">
                    <div class="col-xs-10 w-100 me-2">
                        <input type="text"
                                id="venue"
                                name="venue"
                                class="form-control"
                                placeholder="Venue"
                                [ngModel]="session.Venue && session.Venue.Name"
                                (ngModelChange)="CreateVenueIfNull('Venue', 'Name', $event)"
                                [ngbTypeahead]="getNamedLocation">
                    </div>
                    <div class="ms-auto">
                        <tier-mapping-button [location]="session.Venue" permission="auto" [disabled]="!session.Venue?.Name"><i class="fa fa-map"></i></tier-mapping-button>
                    </div>
                </div>
                <p [hidden]="!searchFailed" class="form-text text-warning">You are creating a new venue.</p>
            </div>

        </div>
        <div class="row mb-3">
            <label for="description" class="col-sm-3 col-form-label fw-bold">Description</label>
            <div class="col-sm-9">
                <textarea class="form-control" placeholder="Description" name="description" id="description" rows="5" [(ngModel)]="session.Description"></textarea>
            </div>
        </div>
        <div class="row mb-3">
            <label for="faciliators" class="col-sm-3 col-form-label fw-bold">Faciliators</label>
            <div class="col-sm-9">
                <tierdropdown
                    name="fadd"
                    #fadd="ngModel"
                    source="api/teammembers"
                    placeholder="Select Team member"
                    [(ngModel)]="session.Faciliators"
                    [DuplicateEntryValidation]="session.Attendees"
                    [multi]=true
                    bindValue="FullName">
                </tierdropdown>
                <p [hidden]="fadd.valid" class="form-text text-danger">A faciliator cannot also be an attendee.</p>
            </div>
        </div>
        <div class="row mb-3">
            <label for="objective" class="col-sm-3 col-form-label fw-bold">Objective</label>
            <div class="col-sm-9">
                <textarea class="form-control" name="objective" placeholder="Objective" id="objective" rows="5" [(ngModel)]="session.Objective"></textarea>
            </div>
        </div>
        <div class="row mb-3">
            <label for="outcome" class="col-sm-3 col-form-label fw-bold">Outcome</label>
            <div class="col-sm-9">
                <textarea class="form-control" name="outcome" placeholder="Outcome" id="outcome" rows="5" [(ngModel)]="session.Outcome"></textarea>
            </div>
        </div>
        <div class="row mb-3">
            <label for="review" class="col-sm-3 col-form-label fw-bold">Review</label>
            <div class="col-sm-9">
                <textarea class="form-control" name="review" placeholder="Review" id="review" rows="5" [(ngModel)]="session.Review"></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div>
            <button type="button" class="btn btn-info me-2"
                    (click)="complete()"
                    [hidden]="!session.Id"
                    [disabled]="sessionForm.invalid || session.IsActive === false || !session.Attendees || !session.SkillModules || session.Attendees.length <= 0 || session.SkillModules.length <= 0">
                Complete
            </button>
            <button type="button" class="btn me-2"
                    (click)="delete()"
                    [hidden]="!session.Id"
                    [disabled]="sessionForm.invalid"
                    [ngClass]="{'btn-success': session.IsActive, 'btn-danger': !session.IsActive}"
                    [textContent]="session.IsActive ? 'Active' : 'Inactive'">
            </button>
            <button type="button" class="btn btn-secondary me-2"
                    (click)="dismiss()">
                Cancel
            </button>
            <button type="button" class="btn btn-primary me-2"
                    (click)="ok()"
                    [disabled]="sessionForm.invalid">
                Save
            </button>
        </div>
    </div>
</form>

