import packageInfo from '../../package.json';
import { VERSION } from './version';

export const environment = {
    "name": "development",
    "version": packageInfo.version + '-' + VERSION.hash,
    "menu": [
      {
          "name": "Dashboard",
          "icon": "fa-columns",
          "permissions": null,
          "showOnFaciliator": false,
          "ident": "dashboard",
          "children": null,
          "isRoot" : true
      },
      {
          "name": "Training",
          "icon": "fa-train",
          "permissions": null,
          "showOnFaciliator": false,
          "ident": null,
          "isRoot" : true,
          "children": [
              {
                  "name": "Modules",
                  "icon": "fa-box",
                  "permissions": [ "admin", "trainingadmin" ],
                  "showOnFaciliator": false,
                  "ident": "training@modules",
                  "children": null,
                  "isRoot" : false
              },
              {
                  "name": "Role Layout",
                  "icon": "fa-boxes",
                  "permissions": null,
                  "showOnFaciliator": false,
                  "ident": "training@rolelayout",
                  "children": null,
                  "isRoot" : false
              },
              {
                  "name": "Sessions",
                  "icon": "fa-running",
                  "permissions": [ "admin", "trainingadmin" ],
                  "showOnFaciliator": true,
                  "ident": "training@sessions",
                  "children": null,
                  "isRoot" : false
              },
              {
                  "name": "Assessments",
                  "icon": "fa-list",
                  "permissions": [ "admin", "trainingadmin" ],
                  "showOnFaciliator": false,
                  "ident": "training@assessment",
                  "children": null,
                  "isRoot" : false
              },
              {
                  "name": "Schedule",
                  "icon": "fa-calendar-week",
                  "permissions": null,
                  "showOnFaciliator": false,
                  "ident": "training@schedule",
                  "children": null,
                  "isRoot" : false
              }
          ]
      },
      {
          "name": "Incidents",
          "icon": "fa-ambulance",
          "permissions": null,
          "showOnFaciliator": false,
          "ident": null,
          "isRoot" : true,
          "children": [
              {
                  "name": "Incidents",
                  "icon": "fa-car-crash",
                  "permissions": [ "admin", "incidentadmin" ],
                  "showOnFaciliator": false,
                  "ident": "incidents@incidents",
                  "children": null,
                  "isRoot" : false
              },
              {
                  "name": "Responding",
                  "icon": "fa-reply",
                  "permissions": null,
                  "showOnFaciliator": false,
                  "ident": "incidents@responding",
                  "children": null,
                  "isRoot" : false
              }
          ]
      },
      {
          "name": "Equipment",
          "icon": "fa-truck-loading",
          "permissions": null,
          "showOnFaciliator": false,
          "ident": null,
          "isRoot" : true,
          "children": [
              {
                  "name": "Templates",
                  "icon": "fa-hard-hat",
                  "permissions": [ "admin", "equipmentadmin", "equipmentmanager" ],
                  "showOnFaciliator": false,
                  "ident": "equipment@templates@view",
                  "children": null,
                  "isRoot" : false
              },
              {
                  "name": "Resources",
                  "icon": "fa-fingerprint",
                  "permissions": [ "admin", "equipmentadmin", "equipmentmanager" ],
                  "showOnFaciliator": false,
                  "ident": "equipment@resources@view",
                  "children": null,
                  "isRoot" : false
              }
          ]
      },
      {
          "name": "Reports",
          "icon": "fa-chart-pie",
          "permissions": null,
          "showOnFaciliator": false,
          "ident": null,
          "isRoot" : true,
          "children": [
              {
                  "name": "Supplied Reports",
                  "icon": "fa-parachute-box",
                  "permissions": [ "admin", "reportadmin", "equipmentadmin", "equipmentmanager" ],
                  "showOnFaciliator": false,
                  "ident": "reports@index",
                  "children": null,
                  "isRoot" : false
              },
              {
                  "name": "Report Builder",
                  "icon": "fa-building",
                  "permissions": [ "admin", "reportadmin" ],
                  "showOnFaciliator": false,
                  "ident": "reports@reportbuilder",
                  "children": null,
                  "isRoot" : false
              }
          ]
      },
      {
          "name": "Management",
          "icon": "fa-user-lock",
          "permissions": null,
          "showOnFaciliator": true,
          "ident": null,
          "isRoot" : true,
          "children": [
              {
                  "name": "Team Members",
                  "icon": "fa-user-friends",
                  "permissions": [ "admin", "teamadmin" ],
                  "showOnFaciliator": false,
                  "ident": "management@teammembers",
                  "children": null,
                  "isRoot" : false
              },
              {
                  "name": "Roles",
                  "icon": "fa-user-tag",
                  "permissions": [ "admin" ],
                  "showOnFaciliator": false,
                  "ident": "management@roles",
                  "children": null,
                  "isRoot" : false
              },
              {
                  "name": "Audit Log",
                  "icon": "fa-warehouse",
                  "permissions": [ "admin", "teamadmin", "trainingadmin" ],
                  "showOnFaciliator": false,
                  "ident": "management@auditlog",
                  "children": null,
                  "isRoot" : false
              },
              {
                  "name": "Messages",
                  "icon": "fa-inbox",
                  "permissions": [ "admin" ],
                  "showOnFaciliator": false,
                  "ident": "management@messages",
                  "children": null,
                  "isRoot" : false
              },
              {
                  "name": "Contacts",
                  "icon": "fa-address-book",
                  "permissions": null,
                  "showOnFaciliator": false,
                  "ident": "management@contacts",
                  "children": null,
                  "isRoot" : false
              },
              {
                  "name": "Emergency Contacts",
                  "icon": "fa-address-card",
                  "permissions": [ "admin", "teamadmin" ],
                  "showOnFaciliator": false,
                  "ident": "management@emergencycontacts",
                  "children": null,
                  "isRoot" : false
              },
              {
                  "name": "Settings",
                  "icon": "fa-toolbox",
                  "permissions": [ "admin" ],
                  "showOnFaciliator": false,
                  "ident": "management@settings",
                  "children": null,
                  "isRoot" : false
              }
           ]
      }
  ],
  "sideMenu": [
      {
          "name": "Profile",
          "icon": "fa-user",
          "permissions": null,
          "showOnFaciliator": false,
          "ident": "account@profile",
          "children": null,
          "isRoot" : false
      },
      {
          "name": "Training Assessments",
          "icon": "fa-list",
          "permissions": null,
          "showOnFaciliator": false,
          "ident": "account@training",
          "children": null,
          "isRoot" : false
      },
      {
          "name": "Equipment Checkout",
          "icon": "fa-fire-extinguisher",
          "permissions": null,
          "showOnFaciliator": false,
          "ident": "account@equipmentcheckout",
          "children": null,
          "isRoot" : false
      }
  ],
    "apiUrl": "https://devapi.sartier.co.uk/",
    "appinsightskey" : "0d73f85a-26c3-4247-bb59-373483679f59",
    "clientId": "TIERDEV",
    "production": false,
    "siteDefaultTitle": "T.I.E.R (DEV)",
    "defaultUserIcon": "/images/default-profile.jpg",
    "googleMapLink": "https://www.google.com/maps/search/?api=1&query=",
    "profilePictureURLTemplate": "api/teammembers/%s/profilepicture",
    "variant": "sar"
};
