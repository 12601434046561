<div class="col-md-8 offset-md-2">
    <div class="row">
        <label class="col-sm-3 col-form-label fw-bold">Modules</label>
        <div class="col-sm-9">
            <tierdropdown
                name="moddd"
                source="api/modules"
                placeholder="Select Module Or Create New"
                newLabel="Create New Module"
                (ngModelChange)="get($event)"
                [(ngModel)]="selectedModuleId"
                [httpparams]="{ ShowInactive: true }"
                [allowNew]=true
                bindId="Id"
                bindValue="Name"
                [refresh]="refreshEmitter">
            </tierdropdown>
        </div>
    </div>
    <hr />
    <form role="form" #moduleForm="ngForm" name="moduleForm" (ngSubmit)="save()" novalidate autocomplete="none">
        <div class="row p-2">
            <ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
                <li [ngbNavItem]="1">
                    <button ngbNavLink>Details</button>
                    <ng-template ngbNavContent>
                        <div class="row mb-3">
                            <label for="dob" class="col-sm-3 col-form-label fw-bold">Name</label>
                            <div class="col-sm-9">
                                <input type="text" id="name" #name="ngModel" name="name" [ngClass]="{ 'is-invalid': name.invalid, 'is-valid': name.valid && !name.pristine }" class="form-control" placeholder="Name" [(ngModel)]="module.Name" required>
                                <p [hidden]="name.valid" class="form-text text-danger">Name is required.</p>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="dob" class="col-sm-3 col-form-label fw-bold">URL</label>
                            <div class="col-sm-9">
                                <input type="url" id="url" name="url" [ngClass]="{ 'is-invalid': url.invalid, 'is-valid': url.valid && !url.pristine }" #url="ngModel" class="form-control" placeholder="URL" [(ngModel)]="module.URL" URLValidation>
                                <p [hidden]="url.valid" class="form-text text-danger">URL Invalid.</p>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="dob" class="col-sm-3 col-form-label fw-bold">Validity Timespan</label>
                            <div class="col-sm-9">
                                <tiertimespan name="validity" #validity="ngModel" [(ngModel)]="module.MonthsValidFor" [options]="['month', 'year']" format="month"></tiertimespan>
                                <p [hidden]="validity.valid" class="form-text text-danger">A value can not be 0 or below.</p>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="dob" class="col-sm-3 col-form-label fw-bold">Priority</label>
                            <div class="col-sm-9">
                                <input type="number" id="priority" [ngClass]="{ 'is-invalid': priority.invalid, 'is-valid': priority.valid && !priority.pristine }" name="priority" #priority="ngModel" class="form-control" placeholder="Priority" [(ngModel)]="module.Priority" min="0" max="99" required>
                                <p [hidden]="!priority.errors?.['required']" class="form-text text-danger">Priority is required.</p>
                                <p [hidden]="!priority.errors?.['max'] && !priority.errors?.['min']" class="form-text text-danger">Priority is required to be a number from 0 to 99.</p>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="dob" class="col-sm-3 col-form-label fw-bold">Frequency</label>
                            <div class="col-sm-9">
                                <input type="number" id="frequency" name="frequency" [ngClass]="{ 'is-invalid': frequency.invalid, 'is-valid': frequency.valid && !frequency.pristine }" #frequency="ngModel" class="form-control" placeholder="Frequency" [(ngModel)]="module.Frequency" min="0" max="99" required>
                                <p [hidden]="!frequency.errors?.['required']" class="form-text text-danger">Frequency is required.</p>
                                <p [hidden]="!frequency.errors?.['max'] && !frequency.errors?.['min']" class="form-text text-danger">Frequency is required to be a number from 0 to 99.</p>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="dob" class="col-sm-3 col-form-label fw-bold">Description</label>
                            <div class="col-sm-9">
                                <textarea class="form-control" placeholder="Description" id="description" name="description" rows="5" [(ngModel)]="module.Description"></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <label for="dob" class="col-sm-3 col-form-label fw-bold">Flags</label>
                            <div class="col-sm-9">
                                <div class="form-check">
                                    <input class="form-check-input" name="Critical" type="checkbox" [(ngModel)]="module.Critical">
                                    <label class="form-check-label">
                                        Critical
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" name="AssessmentBasedFlag" type="checkbox" [(ngModel)]="module.AssessmentBasedFlag">
                                    <label class="form-check-label">
                                        Assessment Based
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" name="IncidentFlag" type="checkbox" [(ngModel)]="module.IncidentFlag">
                                    <label class="form-check-label">
                                        Incident
                                    </label>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <button ngbNavLink>Role</button>
                    <ng-template ngbNavContent>
                         <div class="row mb-3">
                            <label class="col-sm-3 col-form-label fw-bold">Module Roles</label>
                            <div class="col-sm-9">
                                <tier-roleselector name="rselect" [(ngModel)]="module.Roles" bindId="Id" [returnObject]="true"></tier-roleselector>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
        </div>
        <div [ngbNavOutlet]="nav" class="row mt-3"></div>
        <div class="row mb-4">
            <div class="text-end">
                <button type="submit" class="btn btn-primary"
                        [disabled]="moduleForm.invalid || moduleForm.pristine">
                    Save
                </button>
                <button type="buttton" class="btn btn-secondary ms-2"
                        (click)="delete()"
                        [hidden]="!module.Id"
                        [ngClass]="{'btn-success': module.IsActive, 'btn-danger': !module.IsActive}"
                        [textContent]="module.IsActive === true ? 'Active' : 'Inactive'"
                        [disabled]="moduleForm.invalid">
                </button>
            </div>
        </div>
    </form>
</div>
