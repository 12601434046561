import { HttpErrorResponse } from '@angular/common/http';

export function isNorU(value : any) : boolean {
    if(typeof value === 'undefined' || value === null || value === "" || value === 'null' || value?.length === 0)
        return true;

    return false;
}

export function calcUUID() : string {
    return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/ug, function (c) {
        let r = Math.random() * 16 | 0, v = c === 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
    });
};

export function http2Error(error : HttpErrorResponse) : string | null {
    if(error?.status === 401)
        return null;

    if(error instanceof Error) {
        return error?.message;
    } else if(error instanceof HttpErrorResponse && !isNorU(error?.error?.Message)) {
        return error?.error?.Message;
    } else if(error instanceof HttpErrorResponse && !isNorU(error?.error)) {
        return `Unknown error object from HTTPErrorResponse: ${JSON.stringify(error?.error)}`;
    }

    return `Unknown Error: ${JSON.stringify(error)}`;
}

export function size(obj : object) : number {
    let size : number = 0, key : string;

    for (key in obj)
        if (obj.hasOwnProperty(key)) size++;

    return size;
};

export function isObject(obj : any) {
    return obj === Object(obj) && !Array.isArray(obj) && obj !== null;
}

export function randomString(len : number) {
    return [...Array(len)].map(() => Math.random().toString(36)[2]).join('')
}

export function clone(source : any) : any {
    return JSON.parse(JSON.stringify(source))
}
