<div>
    <tierfilter class="mb-3" [config]="filterConfig" [(data)]="values" (fetch)="get()"></tierfilter>
</div>

<div>
    <div [hidden]="!incidents.length">
        <div class="card mb-3" *ngFor="let incident of incidents">
            <div class="card-header">
                <h4 class="m-0"><b>Incident:</b> {{incident.Name}} - Incident has been open {{incident.Start | durationdate: nowString }}</h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-4 mb-3">
                        <div class="img-thumbnail">
                            <tier-mapping [(location)]="incident.ActivityLocation" [tooltip]="true" permissions="r"></tier-mapping>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <div class="card mb-2 bg-light">
                            <div class="card-body">
                                <div class="row mb-3 text-md-end">
                                    <label for="started" class="col-sm-12 col-md-3 col-form-label fw-bold">Started</label>
                                    <div class="col-sm-12 col-md-9">
                                        <input id="started" type="text" readonly class="form-control-plaintext" [value]="(incident.Start  | transformdate: 'dd-MM-yyyy HH:mm') || 'N/A'">
                                    </div>
                                </div>
                                <div class="row mb-3 text-md-end">
                                    <label for="calltype" class="col-sm-12 col-md-3 col-form-label fw-bold">Call Type</label>
                                    <div class="col-sm-12 col-md-9">
                                        <input id="calltype" type="text" readonly class="form-control-plaintext" [value]="incident.IncidentCallTypeName || 'N/A'">
                                    </div>
                                </div>
                                <div class="row mb-3 text-md-end">
                                    <label for="responsetype" class="col-sm-12 col-md-3 col-form-label fw-bold">Response Type</label>
                                    <div class="col-sm-12 col-md-9">
                                        <input id="responsetype" type="text" readonly class="form-control-plaintext" [value]="incident.IncidentResponseTypeName || 'N/A'">
                                    </div>
                                </div>
                                <div class="row mb-3 text-md-end">
                                    <label for="operationtype" class="col-sm-12 col-md-3 col-form-label fw-bold">Operation Type</label>
                                    <div class="col-sm-12 col-md-9">
                                        <input id="operationtype" type="text" readonly class="form-control-plaintext" [value]="incident.IncidentOperationTypeName || 'N/A'">
                                    </div>
                                </div>
                                <div class="row mb-3 text-md-end">
                                    <label for="rvlocation" class="col-sm-12 col-md-3 col-form-label fw-bold">Rv Location</label>
                                    <div class="col-sm-12 col-md-9">
                                        <a *ngIf="incident.RvLocation?.Name" [href]="googleLink + incident.RvLocation?.Lat + ',' + incident.RvLocation?.Lon" target="_blank">
                                            <input id="rvlocation" type="text" readonly class="form-control-plaintext link-primary" [value]="incident.RvLocation?.Name || 'N/A'">
                                        </a>
                                        <input *ngIf="!incident.RvLocation?.Name" id="rvlocation" type="text" readonly class="form-control-plaintext" [value]="incident.RvLocation?.Name || 'N/A'">
                                    </div>
                                </div>
                                <div class="row mb-3 text-md-end">
                                    <label for="details" class="col-sm-12 col-md-3 col-form-label fw-bold">Details</label>
                                    <div class="col-sm-12 col-md-9">
                                        <textarea id="details" type="text" readonly class="form-control-plaintext" [value]="incident.Details || 'N/A'"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="hstack">
                                <div class="ms-auto">
                                    <div *ngIf="(!noTrackTimeOption && !incident.TeamMemberIsCurrentlyAttending) || (noTrackTimeOption && !incident.TeamMemberIsCurrentlyAttending && !incident.TeamMemberHasPreviouslyAttended)">
                                        <button type="button" class="btn btn-primary" (click)="respond(incident)">Respond to Incident</button>
                                    </div>
                                    <div>
                                        <button type="button" class="btn btn-info me-2" (click)="edit(incident)" *ngIf="(!noTrackTimeOption && incident.TeamMemberIsCurrentlyAttending) ||
                                        (noTrackTimeOption && (incident.TeamMemberHasPreviouslyAttended || incident.TeamMemberIsCurrentlyAttending))">
                                            Edit Skills
                                        </button>
                                        <button type="button" class="btn btn-warning" (click)="close(incident)" *ngIf="(!noTrackTimeOption && incident.TeamMemberIsCurrentlyAttending) ||
                                        (noTrackTimeOption && incident.TeamMemberIsCurrentlyAttending && !incident.TeamMemberHasPreviouslyAttended)">
                                            Finish Responding
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer" [hidden]="!incident.NonCurrentIncidentAttendees.length && !incident.CurrentIncidentAttendees.length">
                <div class="mb-3" [hidden]="!incident.NonCurrentIncidentAttendees.length">
                    <div class="mb-3">
                        <span class="fw-bold" [textContent]="!noTrackTimeOption ? 'Previously Attended' : 'Attended'"></span>
                    </div>
                    <div>
                        <div class="d-inline me-2" *ngFor="let nonAttendee of incident.NonCurrentIncidentAttendees">
                            <span class="badge text-bg-primary">
                                {{nonAttendee}}
                            </span>
                        </div>
                    </div>
                </div>
                <div [hidden]="!incident.CurrentIncidentAttendees.length || noTrackTimeOption">
                    <div class="mb-3">
                        <span class="fw-bold" [textContent]="'Currently Attending'"></span>
                    </div>
                    <div>
                        <div class="d-inline me-2" *ngFor="let attendee of incident.CurrentIncidentAttendees">
                            <span class="badge text-bg-primary">
                                {{attendee}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center my-3">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="values.amount" [maxSize]="15" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="get()" />
        </div>

    </div>
</div>

<div class="alert alert-info" role="alert" [hidden]="incidents.length">
    <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
</div>
