import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { VenueModel, WeatherModel } from '../../../models';
import { TIERAPICalls, TIERToast } from '../../../services';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-weather',
    templateUrl: "./TIERWeather.template.html",
    styleUrl: './TIERWeather.component.scss'
})
export class TIERWeatherComponent {
    @Input() location : VenueModel | null | undefined = {};
    @Input() ro : boolean = false;

    @Input() weather : WeatherModel | null | undefined = {};
    @Output() weatherChange : EventEmitter<WeatherModel> = new EventEmitter<WeatherModel>()

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast) {}

    public fillCurrentWeather() {
        this.apicall.get<WeatherModel>('api/currentweather/', new HttpParams({'fromObject': { ...this.location }})).subscribe({
            next: (response : WeatherModel) => {
                this.weatherChanged(response);
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    private weatherChanged(weather : WeatherModel) {
        this.weather = weather;
        this.weatherChange.emit(weather);
    }

    public CreateObjIfNull(ref : keyof WeatherModel, value : any) : void {
        if(!this.weather)
            this.weather = {};

        this.weather![ref] = value;
    }
}
