import { Component, Inject, AfterViewInit } from '@angular/core';
import { ReportView } from '@easyquery/ui'
import '@easyquery/enterprise';

import { TIERHelpers, TIERAuth } from '../../services';

@Component({
    selector: 'tier-reports-reportbuilder',
    templateUrl: './reportbuilder.template.html',
    styleUrl: './reportbuilder.component.scss'
})
export class ReportBuilderComponent implements AfterViewInit {

    constructor(@Inject(TIERHelpers) protected help : TIERHelpers,
                @Inject(TIERAuth) protected auth : TIERAuth) { }

    ngAfterViewInit(): void {
       const viewOptions = {
            locale: "en-UK",
            calcTotals: true,
            syncReportOnChange: true,
            loadModelOnStart: true,
            defaultModelId: 'tier',
            enableExport: true,
            serverExporters: ['pdf', 'excel', 'excel-html', 'csv'],
            handlers: {
                onError: function (_ : any, error : any) {
                    console.error(error.sourceError);
                }
            },
            widgets: {
                columnsBar: {
                    accentActiveColumn: false,
                    allowAggrColumns: true,
                    attrElementFormat: "{entity} {attr}",
                    showColumnCaptions: true,
                    adjustEntitiesMenuHeight: false,
                    sortEntities: true,
                    menuOptions: {
                        showSearchBoxAfter: 30,
                        activateOnMouseOver: true
                    }
                },
                columnsPanel: {
                    sortEntities: true
                },
                queryPanel: {
                    alwaysShowButtonsInPredicates: false,
                    adjustEntitiesMenuHeight: false,
                    dateFormatValue: "dd/MM/yyyy",
                    dateFormatDisplay: "dd/MM/yyyy",
                    sortEntities: true,
                    menuOptions: {
                        showSearchBoxAfter: 20,
                        activateOnMouseOver: true
                    }
                }
            }
        };

        let reportView = new ReportView();
        document['ReportView'] = reportView;

        let httpClient = reportView.getContext().getServices().getHttpClient();
        httpClient.onRequest = (request) => {
            request.setHeader('Authorization', 'Bearer ' + this.auth.getToken().token);
        };

        reportView.getContext()
            .useEndpoint(this.help.as('/api/reportbuilder'))
            .useEnterprise(function () {
                reportView.init(viewOptions);
            });
    }
}
