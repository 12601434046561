'use strict'

import { Pipe, PipeTransform } from '@angular/core';
import { clone } from 'src/tier/tier.utils';

@Pipe({ name: 'searchRecursive' })
export class SearchRecursiveTextPipe implements PipeTransform {
    transform(value: any[], args?: { text: string, keywords: string[] }) : any[] {
        if (!value || !args || !args.text.trim()) return value;

        let list : any[] = [];
        this.recursiveSearch(list, value, args.text.trim().toLowerCase(), args.keywords);
        return list;
    }

    private recursiveSearch(list : any[], values : any[], search : string, keywords : string[]) {
        for(let i=0; i < values.length; i++) {
            if(values[i]?.Children?.length > 0)
                this.recursiveSearch(list, values[i]?.Children, search, keywords);
            if(this.match(values[i], search, keywords))
                this.add(list, clone(values[i]));
        }
    }

    private selectedObjectValues(value : any, keywords : string[]) : any[] {
        if(typeof value !== 'object')
            return [];

        let list : any[] = [];
        for(let i=0; i<keywords.length;i++) {
            if(keywords[i] in value)
                list.push(value[keywords[i]]);
        }
        return list;
    }

    private add(list : any[], value : any) {
        if(value?.IsCollapsed)
            value.IsCollapsed = true;

        list.push(value);
    }

    private match(value : any, search : string, keywords : string[]) : boolean {
        switch(typeof value) {
            case 'object':
                return this.selectedObjectValues(value, keywords).filter(
                    (entry) => {
                        if(entry) {
                            return entry.toString().toLowerCase().indexOf(search) !== -1;
                        };
                        return false;
                    }).length !== 0;
            case 'string':
                if(value !== null) {
                    return value.toString().toLowerCase().indexOf(search) !== -1;
                };
                return false;
            default:
                return false;
        };
    }

}
