import { Component, Inject, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { TIERAPICalls, TIERToast } from '../../services';
import { SkillModel } from '../../models/';
import { isNorU, http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-modules',
    templateUrl: "./modules.template.html"
})
export class ModulesComponent {
    @ViewChild('moduleForm') moduleForm!: NgForm;

    public refreshEmitter : EventEmitter<string | number> = new EventEmitter();

    public selectedModuleId : number = -1;

    public moduleDefault : SkillModel = { MonthsValidFor: null, Frequency: null };
    public module : SkillModel = { ...this.moduleDefault };

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast) { };

    private clean() : void {
        this.module = { ...this.moduleDefault };
        this.moduleForm.form.markAsPristine();
    }

    public get(id : number) : void {
        if(id === -1) {
            this.clean();
            return;
        }

        this.apicall.get<SkillModel>('api/modules/%s'.formUri(id)).subscribe({
            next: (response : SkillModel) => {
                this.module = response;
                this.moduleForm.form.markAsPristine();
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public save() : void {
        this.apicall.save<SkillModel>('api/modules/%s'.formUri(this.module?.Id), this.module).subscribe({
            next: (response? : SkillModel) => {
                let id = isNorU(response) ? this.module?.Id : response!.Id;

                this.refreshEmitter.emit(id);
                this.get(id!);
                this.alert.success("Module saved.");
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public delete() : void {
        this.module.IsActive = !this.module.IsActive;
        this.save();
    }
}
