import { Component, OnInit, Inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TIERAPICalls, TIERToast } from '../../services';
import { EquipmentResourceTreeModel, EquipmentResourceModel } from '../../models';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-equipment-treeselector',
    templateUrl: "./equipmentresourcetreeselector.template.html"
})
export class EquipmentResourceTreeSelectorComponent implements OnInit {
    @Input() equipment : EquipmentResourceModel[] = [];
    public searchText : string = '';

    public resources : EquipmentResourceTreeModel[] = [];

    constructor(
        @Inject(NgbActiveModal) public activeModal : NgbActiveModal,
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
      ) {}

    ngOnInit(): void {
        this.apicall.get<EquipmentResourceTreeModel[]>('api/equipment/resources/tree').subscribe({
            next: (response : EquipmentResourceTreeModel[]) => {
                this.resources = response;
                this.selectPrevious();
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    private selectPrevious() {
        this.equipment.map(x => x.Id as number).forEach((equipmentId : number) => {
            let match = this.recursiveFind(this.resources, equipmentId);
            if(match)
                match.IsSelected = true;
        });
    }

    private recursiveFind(list : EquipmentResourceTreeModel[], equipmentId :  number) : EquipmentResourceTreeModel | undefined {
        for(let i = 0; i < list.length; i++) {
            if(list[i].Children.length > 0) {
                let value = this.recursiveFind(list[i].Children, equipmentId)
                if(value)
                    return value;
            }

            if(list[i].Id === equipmentId)
                return list[i];
        }
        return undefined;
    }

    public confirm() {
        let list : EquipmentResourceModel[] = [];

        this.selected(list, this.resources)
        this.activeModal.close(list);
    }

    private selected(list : EquipmentResourceModel[], resources : EquipmentResourceTreeModel[]) {
        resources.forEach((resource : EquipmentResourceTreeModel) => {
            if(resource.Children.length > 0)
                this.selected(list, resource.Children);
            if(resource.IsSelected)
                list.push(resource);
        });
    }

    public toggle(state : boolean) {
        this.resources.forEach((resource : EquipmentResourceTreeModel) => {
            resource.IsCollapsed = state;
        });
    }
}
