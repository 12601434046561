import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EquipmentResourceTreeModel } from '../../models';

@Component({
    selector: 'tier-equipment-treeselector-node',
    styleUrl: './equipmentresourcetreenode.component.scss',
    template: `
        <ul class="list-group">
            <li class="list-group-item" *ngFor="let resource of nodes | searchRecursive: { text: search, keywords: ['Reference', 'CheckedByName', 'ConditionName', 'TemplateName'] }" [ngClass]="{ 'list-group-item-danger': !resource.IsAllocatable, 'list-group-item-warning': resource.IsExpired || resource.IsExpiring || !resource.IsChecked || resource.ConditionBad }">
                <div>
                    <div class="fw-bold">
                        <span class="fa-solid me-2" (click)="toggle(resource)" [ngClass]="{ 'fa-folder-open': resource.IsContainer === true && !resource.IsCollapsed, 'fa-folder': resource.IsContainer === true && resource.IsCollapsed, 'fa-barcode': !resource.IsContainer }"></span>
                        <input class="form-check-input me-2" type="checkbox" [disabled]="!resource.IsAllocatable" [(ngModel)]="resource.IsSelected" (change)="this.nodesChange.emit(this.nodes)">
                        <span (click)="toggle(resource)">( {{resource.TemplateName }} ) - {{resource.Reference}}</span>
                    </div>
                    @if(!resource.IsAllocatable) {
                        <span>Resource cannot be allocated</span>
                    } @else if (resource.ConditionBad) {
                        <span>Resource condition is {{resource.ConditionName || 'not set'}}</span>
                    } @else if (resource.IsExpired) {
                        <span>Resource is expiring.</span>
                    } @else if (resource.IsExpired) {
                        <span>Resource is expired</span>
                    } @else if (!resource.IsChecked) {
                        <span>Resource has not been checked</span>
                    }
                </div>
                <tier-equipment-treeselector-node [hidden]="resource.IsCollapsed" *ngIf="resource.Children.length" [nodes]="resource.Children"></tier-equipment-treeselector-node>
            </li>
        </ul>`
})
export class EquipmentResourceTreeNodeComponent {
    @Input() search : string = '';

    @Input() nodes : EquipmentResourceTreeModel[] = [];
    @Output() nodesChange : EventEmitter<EquipmentResourceTreeModel[]> = new EventEmitter<EquipmentResourceTreeModel[]>();

    public toggle(resource : EquipmentResourceTreeModel) {
        if(!resource.IsContainer)
            return;

        resource.IsCollapsed = !resource.IsCollapsed;
    }
}
