'use strict';

import { Injectable, Inject, EventEmitter } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable, tap, throwError } from 'rxjs';
import JwtDecode from "jwt-decode";

import { TIERLocalStorage, TIERAPICalls, TIERConfig } from './';

const claimTypes = {
    name: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name",
    nameIdentifier: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier",
    role: "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
};

export interface LoginInformation {
    email : string,
    password: string,
    remember : boolean
}

export interface TIERAuthenticationToken {
    isAuth : boolean,
    eMail : string | null,
    token : string | null,
    refreshToken: string | null
    remember : boolean
}

export interface TokenInformation {
    username: string,
    userId: string,
    fullname: string,
    organizationId: number,
    organizationName: string,
    avatarURL: string
}

@Injectable({
    providedIn: 'root'
})
export class TIERAuth
{
    public LoginEvent = new EventEmitter();
    public LogoutEvent = new EventEmitter();
    private readonly authStorageKey = 'authorizationData';
    private authenticationToken : TIERAuthenticationToken = {
        'isAuth': false,
        'eMail': null,
        'token': null,
        'refreshToken': null,
        'remember': false
    };

    constructor(
        @Inject(TIERLocalStorage) private localstore : TIERLocalStorage,
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERConfig) private config: TIERConfig) { }

    public EmitLogin() : void {
        this.LoginEvent.emit();
    }

    public EmitLogout() : void {
        this.LogoutEvent.emit();
    }

    private getTokenForMultipleSources() : TIERAuthenticationToken {
        let authData = this.localstore.get(this.authStorageKey) as TIERAuthenticationToken;
        if(!authData)
            authData = this.localstore.get(this.authStorageKey, sessionStorage) as TIERAuthenticationToken

        return authData;
    }

    public fetchTokenFromStorage() : void {
        let authData = this.getTokenForMultipleSources();

        if(authData == null)
            return;

        this.setToken({ 'isAuth': true, 'token': authData.token, 'eMail': authData.eMail, 'refreshToken': authData.refreshToken, 'remember': authData.remember }, authData.remember);
    }

    private setToken(token : object, isLocal : boolean = true) : void {
        this.authenticationToken = token as TIERAuthenticationToken;
        this.localstore.set(this.authStorageKey, this.authenticationToken, isLocal ? localStorage: sessionStorage);
    }

    public getToken() : TIERAuthenticationToken {
        return this.authenticationToken;
    }

    public logout() : void {
        this.localstore.remove(this.authStorageKey);
        this.localstore.remove(this.authStorageKey, sessionStorage);

        this.authenticationToken = {
            'isAuth': false,
            'eMail': null,
            'token': null,
            'refreshToken': null,
            'remember': false
        };

        this.EmitLogout();
    }

    public login(loginInfo : LoginInformation) : Observable<object> {
        return this.apicall.post('token',
            "grant_type=password&username=" + loginInfo.email + "&password=" + loginInfo.password + "&client_id=" + this.config.get('clientId'),
            undefined,
            new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        ).pipe(tap((response : any) => {
            this.setToken({ 'isAuth': true, 'token': response.access_token, 'eMail': response.userName, 'refreshToken': response.refresh_token, 'remember': loginInfo.remember }, loginInfo.remember);
            this.EmitLogin();
        }));
    }

    public refreshToken() : Observable<any> {
        let authData = this.getTokenForMultipleSources();

        if(!authData)
            return throwError(() => new Error("No auth data"));

        return this.apicall.post('token',
            "grant_type=refresh_token&refresh_token=" + authData.refreshToken + "&client_id=" + this.config.get('clientId'),
            undefined,
            new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        ).pipe(tap((response : any) => {
            this.setToken({ 'isAuth': true, 'token': response.access_token, 'eMail': response.userName, 'refreshToken': response.refresh_token, 'remember': authData.remember }, authData.remember)
        }));
    }

    public obtainAccessToken(externalData : object) : Observable<any> {
        return new Observable(obs => {
            this.apicall.post('api/teammembers/ObtainLocalAccessToken',
                externalData,
                undefined,
                undefined
            ).subscribe({
                next: (response : any) => {
                    this.setToken({ 'isAuth': true, 'token': response.access_token, 'eMail': response.userName })
                    this.EmitLogin();
                    obs.next(response);
                },
                error: (error) => {
                    obs.error(error);
                }
            });
        });
    }

    public isLoggedIn() : boolean {
        return this.getToken().isAuth;
    };

    public hasClaim(claims : any) : boolean {
        if (!Array.isArray(claims))
            return false;

        if (this.getToken().token) {
            let decoded : any = JwtDecode(this.getToken().token!);

            if (typeof decoded[claimTypes.role] === 'undefined')
                return false;

            for (let i = 0; i < claims.length; i++) {
                if (Array.isArray(decoded[claimTypes.role])) {
                    let roles = decoded[claimTypes.role].map(function (v : string) { return v.toLowerCase(); });
                    if (roles.indexOf(claims[i].toLowerCase()) !== -1) {
                        return true;
                    }
                } else {
                    if (decoded[claimTypes.role].toLowerCase() === claims[i].toLowerCase()) {
                        return true;
                    }
                }
            }
        }

        return false;
    };

    public getTokenInfo() : TokenInformation | null  {
        let authToken = this.getToken();

        if(authToken.isAuth)
            if (authToken.token !== null) {
                let decoded = JwtDecode(authToken.token) as any;
                return {
                    username: decoded[claimTypes.name],
                    userId: decoded[claimTypes.nameIdentifier],
                    fullname: decoded.Fullname,
                    organizationId: decoded.OrganizationId,
                    organizationName: decoded.OrganizationName,
                    avatarURL: decoded.AvatarURL
                } as TokenInformation
            }

        return null;
    };
}
