<div class="card mb-3" *ngIf="!userState">
    <div class="card-header">
        Query Selection
    </div>
    <div class="card-body">
        <div class="row">
            <label class="col-sm-3 col-form-label fw-bold">Team Member</label>
            <div class="col-sm-9">
                <tierdropdown
                    name="tmdd"
                    source="api/teammembers"
                    placeholder="Select User"
                    (ngModelChange)="get($event)"
                    [(ngModel)]="selectedTeammemberId"
                    [allowNew]=false
                    bindId="Id"
                    bindValue="FullName">
                </tierdropdown>
            </div>
        </div>
    </div>
</div>

<div [hidden]="!selectedTeammemberId">
    <tierfilter class="mb-3" [config]="filterConfig" [(data)]="values" (fetch)="get(selectedTeammemberId)"></tierfilter>
</div>

<div [hidden]="!assessments.length">

    <div class="hstack">
            <div class="ms-auto">
                <input class="form-control"
                    type="text"
                    placeholder="Search..."
                    [(ngModel)]="searchText">
            </div>
    </div>

    <div class="mb-4">
        <div class="mb-1 fw-bold">
            Colour Key:
        </div>
        <div class="hstack ms-4">
            <div class="me-2 hstack">
                <div class="text-bg-success rounded-2 p-2 me-2">
                </div>
                <div>
                    <span>Active skill</span>
                </div>
            </div>
            <div class="me-2 hstack">
                <div class="text-bg-danger rounded-2 p-2 me-2">
                </div>
                <div>
                    <span>Inactive skill</span>
                </div>
            </div>
            <div class="me-2 hstack">
                <div class="text-bg-warning rounded-2 p-2 me-2">
                </div>
                <div>
                    <span>Expiring skill</span>
                </div>
            </div>
            <div class="me-2 hstack">
                <div class="text-bg-info rounded-2 p-2 me-2">
                </div>
                <div>
                    <span>Unmarked skill</span>
                </div>
            </div>
        </div>
    </div>

    <div class="table-responsive mb-3">
        <table class="table">
            <thead>
                <tr>
                    <th class="text-center" *ngIf="!userState || (userState && allowAttachment)">Add / Edit</th>
                    <th>Module</th>
                    <th>Type</th>
                    <th>Validity</th>
                    <th>Critical</th>
                    <th>Passed</th>
                    <th>Obtained</th>
                    <th>Expires</th>
                    <th class="text-center">Attachment</th>
                    <th>Comments</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let assessment of assessments | search: searchText"
                    [ngClass]="{
                        'table-success': (assessment.Type && assessment.Status === true) ||
                            (!assessment.Type && assessment.AssessDate !== null) && !assessment.AssessmentExpired,
                        'table-danger': (assessment.Type && assessment.Status === false) || assessment.AssessmentExpired,
                        'table-warning': assessment.AssessmentExpiring === true && assessment.AssessmentExpired === false,
                        'table-info': assessment.Type && assessment.Status == null
                    }">
                    <td class="text-center" *ngIf="!userState || (userState && allowAttachment)">
                        <button type="button" class="btn btn-primary btn-sm" (click)="edit(assessment)" [disabled]="userState && !assessment.Id">
                            <i class="fa-solid fa-edit"></i>
                        </button>
                    </td>
                    <td *ngIf="assessment.ModuleURL"><a href="{{assessment.ModuleURL}}">{{assessment.ModuleName}}</a></td>
                    <td *ngIf="!assessment.ModuleURL">{{assessment.ModuleName}}</td>
                    <td>
                        <span class="badge text-bg-primary" [textContent]="assessment.Type ? 'Assessment' : 'Attendence'"></span>
                    </td>
                    <td>{{assessment.Validity || 'N/A'}}</td>
                    <td>
                        <i class="fa-solid" [ngClass]="{ 'fa-check': assessment.Critical, 'fa-times': !assessment.Critical }"></i>
                    </td>
                    <td>
                        <span *ngIf="!assessment.Type">N/A</span>
                        <i *ngIf="assessment.Type" class="fa-solid" [ngClass]="{ 'fa-check': assessment.Status, 'fa-times': !assessment.Status }"></i>
                    </td>
                    <td>{{(assessment.AssessDate | transformdate: 'dd-MM-yyyy') || 'N/A'}}</td>
                    <td *ngIf="assessment.Type && assessment.Status !== true">N/A</td>
                    <td *ngIf="(assessment.Type && assessment.Status === true) || !assessment.Type">{{(assessment.Expires | transformdate: 'dd-MM-yyyy') || 'N/A'}}</td>
                    <td class="text-center">
                        <button type="button" class="btn btn-info btn-sm" (click)="download(assessment.Id)" [disabled]="assessment.Attachment === null">
                            <i class="fa-solid fa-download"></i>
                        </button>
                    </td>
                    <td>{{assessment.Comments}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div [hidden]="assessments.length || selectedTeammemberId === null" class="alert alert-info" role="alert">
    <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
</div>
