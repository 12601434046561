import { Injectable, Inject } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { TIERConfig } from './';

@Injectable()
export class TIERAppInsights {
  appInsights: ApplicationInsights;
  constructor(@Inject(TIERConfig) private config: TIERConfig) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.config.get('appinsightskey'),
        enableAutoRouteTracking: true,
        enableDebug: this.config.get('production') === false,
        disableTelemetry: this.config.get('production') === false
      }
    });
    this.appInsights.loadAppInsights();
  }

  logPageView(name?: string, url?: string) : void  {
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) : void  {
    this.appInsights.trackEvent({ name: name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) : void  {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) : void  {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) : void  {
    this.appInsights.trackTrace({ message: message}, properties);
  }
}
