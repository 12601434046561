'use strict'

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'search' })
export class SearchTextPipe implements PipeTransform {
    transform(value: any[] , args?: string) : any[] {
        if (!value || !args) return value;

        let argument = args.toString().toLowerCase();
        return value.filter((row : any) => {
            switch(typeof row) {
                case 'object':
                    return Object.values(row).filter(
                        (entry) => {
                            if(entry) {
                                return entry.toString().toLowerCase().indexOf(argument) !== -1;
                            };
                            return false;
                        }).length !== 0;
                case 'string':
                    if(row !== null) {
                        return row.toString().toLowerCase().indexOf(argument) !== -1;
                    };
                    return false;
                default:
                    return false;
            }
        });
    }
}
