import { Component, OnInit, Inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { format, addMonths } from "date-fns";

import { TIERAuth, TIERDialog } from '../../services';
import { EquipmentResourceModel, TeammembersModel } from '../../models';
import { isNorU } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-equipment-resourcecheck',
    template: `
        <div class="modal-header">
            <h3 class="modal-title">Check Equipment</h3>
        </div>
        <div class="modal-body">
            <form name="checkEquipmentForm" #checkEquipmentForm="ngForm" novalidate autocomplete="none">
                <div class="row mb-3">
                    <label for="checkdue" class="col-sm-3 col-form-label fw-bold">Check Due</label>
                    <div class="col-sm-9">
                        <tiertimepicker id="checkduedate" #mcheckduedate="ngModel" name="checkduedate" [model]="mcheckduedate" [(ngModel)]="checkDueDate" required></tiertimepicker>
                        <p class="form-text text-danger" [hidden]="mcheckduedate.valid">Check Due must have a valid date.</p>
                    </div>
                </div>

                <div class="row mb-3">
                    <label for="checkedby" class="col-sm-3 col-form-label fw-bold">Checked By</label>
                    <div class="col-sm-9">
                        <tierdropdown
                            id="checkedby"
                            name="checkedby"
                            source="api/teammembers"
                            [httpparams]="{ ShowInactive: false, HasClaim: 'EquipmentManager' }"
                            placeholder="Select Team Member"
                            [(ngModel)]="checkedBy"
                            [allowNew]=false
                            bindValue="FullName"
                            required>
                        </tierdropdown>
                        <p class="form-text text-danger" [hidden]="checkedBy">Checked By must have a valid team member.</p>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <div>
                <button type="button" class="btn btn-secondary me-2"
                        (click)="this.activeModal.dismiss()">
                    Cancel
                </button>
                <button type="button" class="btn btn-primary"
                        (click)="confirm()"
                        [disabled]="checkEquipmentForm.invalid && checkedBy">
                    Confirm
                </button>
            </div>
        </div>`
})
export class EquipmentResourceCheckComponent implements OnInit {
    @Input() resource : EquipmentResourceModel = {};

    public checkedBy : TeammembersModel | null = null;
    public checkDueDate : string | null = null;

    constructor(
        @Inject(NgbActiveModal) public activeModal : NgbActiveModal,
        @Inject(TIERDialog) protected dialog : TIERDialog
      ) { }

    ngOnInit(): void {
        this.checkedBy = this.resource.CheckedBy ?? null;
        this.checkDueDate = this.resource.CheckDueDate ?? null;

        if(
            !isNorU(this.resource?.EquipmentTemplate?.CheckPeriodInMonths) &&
            isNorU(this.checkDueDate)
        ) {
            this.checkDueDate = format(addMonths(new Date(), this.resource.EquipmentTemplate?.CheckPeriodInMonths!), 'dd-MM-yyyy');
        }
    }

    public confirm() : void {
        if(this.resource.EquipmentTemplate?.IsAllowedRecursiveCheck && this.resource.Id) {
            this.dialog.showConfirmDialog("Recursive Check", "Do you wish to additionaly recursively check all children of this resource as well?", "Yes", "Just Parent Entry").then(
                () => {
                    this._confirm(true);
                },
                () => {
                    this._confirm(false);
                }
            ).catch(() => {});
        } else {
            this._confirm(false);
        }
    }

    public _confirm(recursive : boolean) : void {
        this.activeModal.close({
            checkDueDate: this.checkDueDate,
            teamMember: this.checkedBy,
            recursiveCheck: recursive
        });
    }
}
