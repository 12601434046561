import { Component, Input, OnInit, Output, EventEmitter, Inject, OnDestroy, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { TIERAPICalls, TIERToast } from '../../../services';
import { http2Error, isNorU } from 'src/tier/tier.utils';

@Component({
    selector: 'tierdropdown',
    template: ` <ng-select [items]="entries" [bindValue]="bindId" [bindLabel]="bindValue" [groupBy]="group" [groupValue]="groupVal" *ngIf="!multi" [disabled]="disabled" [(ngModel)]="selectedEntry" [placeholder]="placeholder" (change)="updateChanges($event)" (clear)="onClear()" [clearable]="!allowNew && clearable">
                    <ng-template ng-optgroup-tmp let-item="item">
                        {{item}}
                    </ng-template>
                </ng-select>

                <ng-select *ngIf="multi" [items]="entries" [bindValue]="bindId" [bindLabel]="bindValue" [multiple]="multi" [closeOnSelect]="!multi" [disabled]="disabled" [(ngModel)]="selectedEntry" (change)="updateChanges($event)" [clearable]="!allowNew && clearable" [placeholder]="placeholder" (clear)="onClear()">
                    <ng-template ng-header-tmp>
		                <button type="button" (click)="selectAll()" class="btn btn-sm btn-secondary me-2">Select all</button>
		                <button type="button" (click)="unselectAll()" class="btn btn-sm btn-secondary">Unselect all</button>
	                </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item[bindValue]}}
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngFor="let item of items.slice(0,5)">
                        <span class="ng-value-icon" (click)="clear(item)">× |</span>
                            <span class="ng-value-label" [textContent]="item[bindValue]"></span>
                        </div>
                        <div class="ng-value" *ngIf="items?.length > 5">
                            <span class="ng-value-label">{{items?.length - 5}} more...</span>
                        </div>
                    </ng-template>
                </ng-select>`,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TIERDropdownComponent),
        multi: true
    }]
})
export class TIERDropdownComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {
    @Input() source : Array<any> | string | null = null;
    @Input() refresh : EventEmitter<number | string> = new EventEmitter();

    @Input() placeholder : string = 'None';
    @Input() allowNew : boolean = false;
    @Input() httpparams : object | null = null;
    @Input() objectList : boolean = false;
    @Input() default : string | null = null;

    @Input() group : any = null;
    @Input() groupVal : any  = null;

    @Input() bindId : any = null;
    @Input() bindValue : any = "name";
    @Input() newLabel: string = "Create New";

    @Input() multi : boolean = false;
    @Input() clearable : boolean = false;

    @Input() disabled : boolean = false;
    @Input() required : string | boolean = false;
    @Output() clear : EventEmitter<any> = new EventEmitter();
    @Output() change : EventEmitter<any> = new EventEmitter();

    @Input() isNumber : boolean = false;

    public selectedEntry : any = this.multi ? [] : null;
    public entries : Array<any> = [];

    onChange: (_: any) => void = (_: any) => {};
    onTouched: () => void = () => {};

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast
    ) {};

    ngOnInit(): void {
        if(this.multi === true && this.allowNew === true) {
            console.log("Cannot choose multi select with the ability to add new");
            return;
        }

        this.reinit(null);
        this.refresh.subscribe({
            next: (id : number | string) => {
                this.reinit(id);
            }
        })
    };

    ngOnChanges(changes: SimpleChanges): void {
        if(!isNorU(changes['source']?.currentValue) && !changes['source'].firstChange)
            this.reinit(this.selectedEntry);
    }

    ngOnDestroy() : void {
        this.refresh.unsubscribe();
    }

    private reinit(id : number | string | null) : void {
        this.formatSource(id);

        if(this.allowNew)
            this.addNew();
    }

    public selectAll() {
        if(this.bindId !== null) {
            this.selectedEntry = this.entries.map((x) => x[this.bindId!]);
        } else {
            this.selectedEntry = this.entries;
        }

        this.updateChanges(this.selectedEntry);
    }

    public unselectAll() {
        this.selectedEntry = [];
        this.updateChanges(this.selectedEntry);
    }

    private addNew() : void {
        let newValue : any = { 'new': true };

        if(this.bindId !== null)
            newValue[this.bindId] = -1;

        newValue[this.bindValue] = this.newLabel;

        this.entries.unshift(newValue);
        this.writeValue(this.bindId !== null ? -1 : this.entries[0]);
    }

    private formatSource(id : number | string | null) : void {
        if(typeof this.source === 'string')
        {
            this.apicall.get(this.source, new HttpParams({'fromObject': { ...this.httpparams }})).subscribe({
                next: (response : any) => {
                    this.makeEntries(this.objectList ? response['ObjectList'] : response as any[]);

                    if (!this.multi && id !== null)
                        this.writeValue(id);

                    this.selectDefault();
                },
                error: (error) => {
                    this.alert.error(http2Error(error));
                }
              });
        }
        else if (typeof this.source === 'object')
        {
            this.makeEntries((this.source as any[]));
        }
    }

    private selectDefault() {
        if(isNorU(this.selectedEntry) && this.default) {
            let entryObj = this.entries.find((entry : any) => entry[this.default!]);
            this.selectedEntry = this.bindId ? entryObj[this.bindId] : entryObj;

            this.updateChanges(this.selectedEntry);
        }
    }

    private makeEntries(source : any[]) : void {
        if(!Array.isArray(source))
            return console.log("Entries for dropdown is not an array");

        this.entries = [];
        for(let i=0; i < source.length; i++) {
            this.entries = [...this.entries, source[i]];
        }
    }

    public onClear() : void {
        this.clear.emit();
    }

    updateChanges(change : any) : void {
        this.onChange(this.selectedEntry);
        this.change.emit(change);
    }

    writeValue(selected : any): void {
        this.selectedEntry = this.isNumber ? +selected : selected;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
